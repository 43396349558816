import React, { useEffect, useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import DataTableComponent from "react-data-table-component";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import ProfileService from "../../../../services/User/ProfileService";
import SettlementService from "../../../../services/User/SettlementService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const ManageBankAccount = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();
    setLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementService.fetchBanks(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;
        setData(data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
    setLoading(false);
  };

  const columns = [
    {
      name: "Bank Name",
      cell: (row) => (
        <div>
          <span>{row.bank}</span>
        </div>
      ),
    },
    {
      name: "Account Number",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.account}</span>
        </div>
      ),
    },
    {
      name: "IFSC Code",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.ifsc}</span>
        </div>
      ),
    },
    {
      name: "Account Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.account_type}</span>
        </div>
      ),
    },
    {
      name: "Proof of Bank",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.pob_type}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "Active") {
              return (
                <h5>
                  <span class="badge badge-success">Active</span>
                </h5>
              );
            } else if (row.status === "Rejected") {
              return (
                <h5>
                  <span class="badge badge-danger">Rejected: {row.remark}</span>
                </h5>
              );
            } else if (row.status === "Pending") {
              return (
                <h5>
                  <span class="badge badge-warning">Pending for Approval</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Set Primary Bank",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            className="btn btn-success btn-sm"
            title="Primary Bank Account"
            onClick={() => setPrimaryBankHandler(row.account)}
          >
            <i className="fa fa-check"></i> Set Primary Bank
          </button>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(row.account)}
            title="Delete"
          >
            <i className="fa fa-trash-alt"></i> Delete
          </button>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);

  const setPrimaryBankHandler = async (account) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementService.setPrimaryBank(
        token,
        account
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
    fetchData();
  };

  const handleDelete = async (account) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementService.deleteBankAccount(
        token,
        account
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
    fetchData();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Manage Bank Account">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Manage Bank Account</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/add_bank_account");
                        }}
                      >
                        Add Bank Account
                      </button>
                    </div>
                  </div>

                  <div class="row pt-2 pb-2">
                    <div class="card-body">
                      <DataTableComponent
                        noHeader={true}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        highlightOnHover
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ManageBankAccount;
