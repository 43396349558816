import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import AepsService from "../../../../services/User/AepsService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

// import UAEPSModal from "./UAEPSModal"; // Import the modal component

import { useHistory } from "react-router-dom";

const AEPS = () => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);

  const [serviceType, setServiceType] = useState("CASH_WITHDRAWAL");
  const formRef = useRef();
  const amountRef = useRef();

  //   const [isModalOpen, setModalOpen] = useState(false);
  //   const [transactionData, setTransactionData] = useState(null);

  //   const handleOpenModal = () => {
  //     const options = {
  //       clientrefid: "TXN213254215478",
  //       callbackurl: "",
  //       pass_key: "NNK7BD3GVEEQSFC3AYJW544MFQT2F33DS7OG4NJHWCFOFTG2HCKA",
  //       token: "in4U8qk/KGlbbZVCC2tc...",
  //       inputParam: "apisdktestapi",
  //       cd_amount: "",
  //       username: "upitestret",
  //       pagename: "CASH_WITHDRAWAL",
  //       isreceipt: "true",
  //     };
  //     setTransactionData(options);
  //     setModalOpen(true);
  //   };

  //   const submitHandler = async () => {
  //     window.isuSDK("#uaeps").open({
  //       closeButton: true,
  //       title: "<img src='logo.png' alt='UAEPS & Adhaarpay'>",
  //       apiusername: "apisdktestapi",
  //       username: "upitestret",
  //       clientrefid: "TXN201445781425",
  //       token:
  //         "lVD13yeLumstJZwp7yFnK5XWuYmFUNxKarpbAAK5uVZKms4nwF9abywaluFQ2sXk4Ayd002liFDeE8tTpTE2S1r6eYJQlcH3KUU2CZWZjugT3sRIKnQmwMAJCJ90zb+qro2muL0iq5xgQK54qoEPKma2FWLSdb414ASWOrvgsBJjsWJDJ0BUmyNeK7hlWshZy8lJB4psGPJ1F2+55xtEugkkj12KypWQkUO2g2ZMM+QmE23gQPIuqO8Xok1uikAC",
  //       pass_key: "NNK7BD3GVEEQSFC3AYJW544MFQT2F33DS7OG4NJHWCFOFTG2HCKA",
  //       pagename: "CASH_WITHDRAWAL",
  //       isreceipt: "true",
  //       callbackurl: "",
  //       cd_amount: "",
  //       paramA: "",
  //       paramB: "",
  //       paramC: "",
  //     });
  //   };

  const [url, setUrl] = useState();

  const getRedirectUrl = async () => {
    let amount = "";

    if (
      serviceType == "CASH_DEPOSIT" ||
      serviceType == "CASH_WITHDRAWAL" ||
      serviceType == "ADHAAR_PAY"
    ) {
      amount = amountRef.current.value;

      if (!amount) {
        Swal.fire("Opps!", "Please Enter Amount", "error");
        return;
      }
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await AepsService.getRedirectUrl(token, serviceType, amount);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const url = data.data.redirect_url;
        // window.open(url, "_blank");
        setUrl(url);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="AEPS">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">AEPS</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/settlement");
                        }}
                      >
                        Settlement
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => {
                          history.push("/user/aeps_report");
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        {url && (
                          <iframe
                            src={url}
                            width="100%"
                            height="700px"
                            frameBorder="0"
                            allow="geolocation"
                            title="UAEPS Transaction"
                            onLoad={() =>
                              console.log("Iframe loaded successfully.")
                            }
                          />
                        )}

                        {!url && (
                          <div class="col-md-8">
                            <div class="card-body">
                              <div id="uaeps"></div>

                              <div className="text-center plan-btn mt-2 mb-2">
                                <div class="form-group row">
                                  <label class="col-sm-3 col-form-label">
                                    Service Type
                                  </label>
                                  <div class="col-sm-9">
                                    <select
                                      class="form-control"
                                      onChange={(e) => {
                                        setServiceType(e.target.value);
                                      }}
                                    >
                                      <option value="">
                                        ---Select Service Type---
                                      </option>
                                      <option value="CASH_WITHDRAWAL">
                                        CASH WITHDRAWAL
                                      </option>
                                      <option value="MINI_STATEMENT">
                                        MINI STATEMENT
                                      </option>
                                      <option value="BALANCE_ENQUIRY">
                                        BALANCE ENQUIRY
                                      </option>
                                      <option value="ADHAAR_PAY">
                                        AADHAAR PAY
                                      </option>
                                      {/* <option value="CASH_DEPOSIT">
                                        CASH DEPOSIT
                                      </option> */}
                                    </select>
                                  </div>
                                </div>

                                {serviceType === "CASH_DEPOSIT" && (
                                  <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">
                                      Amount
                                    </label>
                                    <div class="col-sm-9">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        placeholder="Amount"
                                        ref={amountRef}
                                      />
                                    </div>
                                  </div>
                                )}

                                {serviceType === "CASH_WITHDRAWAL" && (
                                  <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">
                                      Amount
                                    </label>
                                    <div class="col-sm-9">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        placeholder="Amount"
                                        ref={amountRef}
                                      />
                                    </div>
                                  </div>
                                )}

                                {serviceType === "ADHAAR_PAY" && (
                                  <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">
                                      Amount
                                    </label>
                                    <div class="col-sm-9">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        placeholder="Amount"
                                        ref={amountRef}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* <button onClick={handleOpenModal}>
                                Start UAEPS Transaction
                              </button>
                              <UAEPSModal
                                isOpen={isModalOpen}
                                onClose={() => setModalOpen(false)}
                                options={transactionData}
                              /> */}

                                <div class="card-footer text-center mt-5">
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={getRedirectUrl}
                                  >
                                    Redirect to AEPS Panel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {url && (
                          <div class="text-center mt-4 mb-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => {
                                setUrl();
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AEPS;
