import React, { useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import { Chip } from "@material-ui/core";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import SettlementBanksService from "../../../services/Admin/SettlementBanksService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import ActionDialog from "./ActionDialog";

const UserBankDetailsList = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();

  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    SettlementBanksService.fetch(token, userId, searchText, status, page, size)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getImageLink = async (user_id, account) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementBanksService.getImageLink(
        token,
        user_id,
        account
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;

        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Bank Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.bank}</span>
        </div>
      ),
    },
    {
      name: "Account Details",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>A/C: {row.account}</span> <br />
          <span>IFSC: {row.ifsc}</span>
        </div>
      ),
    },
    {
      name: "Account Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.account_type}</span>
        </div>
      ),
    },
    {
      name: "POB Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.pob_type}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "Active") {
              return (
                <h5>
                  <span class="badge badge-success">Active</span>
                </h5>
              );
            } else if (row.status === "Rejected") {
              return (
                <h5>
                  <span class="badge badge-danger">Rejected</span>
                </h5>
              );
            } else if (row.status === "Pending") {
              return (
                <h5>
                  <span class="badge badge-warning">Pending</span>
                </h5>
              );
            } else if (row.status === "Suspended") {
              return (
                <h5>
                  <span class="badge badge-danger">Suspended</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="User Bank Details List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User Bank Details List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="Active">Active</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Rejected">Rejected</option>
                                  <option value="Suspended">Suspended</option>
                                </select>
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <ActionDialog
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        selectedData={selectedData}
        getImageLink={getImageLink}
      />
    </React.Fragment>
  );
};

export default UserBankDetailsList;
