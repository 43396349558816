import common from "../../utill/Common";

class AepsService {
  getRedirectUrl = async (token, service_type, amount) => {
    const url = common.baseUrl + "user/aeps/get_redirect_url";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ service_type, amount }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  report = async (
    token,
    from_date,
    to_date,
    status,
    service_type,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/aeps/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        service_type,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AepsService();
