import { useState, useEffect } from "react";

const UAEPS = () => {
  const [formData, setFormData] = useState({
    apiusername: "apisdktestapi",
    username: "upitestret",
    clientrefid: "TXN213254215478",
    token:
      "in4U8qk/KGlbbZVCC2tcQshkKy4IcBOzz1Z69VSPTvH5vM9YsctxEQTz1QIUSm84TCYktp5z6IRRjumwcMLJ01RVwKhZf5odPJLZB+OoXWp1SkwT51FI+eJTmWXHnvkWMJmR+VWLtTl5hLj2A5fXA/4q0lj+TAGj0wYMRYe+fPL/+BHNRusPu2OP6fXEOweKl0DsPDpOT3Fnlw/4XJJoPzPbgcntMWu6R+e46c0U7roR05I+ixNA7+Vjp3qw4Xjw",
    pass_key: "NNK7BD3GVEEQSFC3AYJW544MFQT2F33DS7OG4NJHWCFOFTG2HCKA",
    pagename: "CASH_WITHDRAWAL",
    isreceipt: "true",
    callbackurl: "",
    cd_amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const showAmountField = () => formData.pagename === "CASH_DEPOSIT";
  const showCallbackField = () => formData.isreceipt === "false";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/isu-sdk/isuSDK.min.js"; // Adjust if needed
    script.async = true;
    script.onload = () => console.log("SDK Loaded Successfully");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => console.log("Location: ", position.coords),
        (error) => {
          if (error.code === 1) alert("Location permission denied");
        }
      );
    }
  }, []);

  useEffect(() => {
    const handleTransactionComplete = (event) => {
      if (event.data === "transactionCpmpleted") {
        const ele = document.getElementsByClassName("isuSDK-overlay");
        const ele2 = document.getElementsByClassName("zoom-and-spin");
        const ele4 = document.getElementsByClassName("isuSDK-modal");

        if (ele[0]) ele[0].remove();
        if (ele2[0]) ele2[0].remove();
        if (ele4[0]) ele4[0].remove();
      }
    };

    window.addEventListener("message", handleTransactionComplete);
    return () =>
      window.removeEventListener("message", handleTransactionComplete);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { token, pagename, pass_key, isreceipt, callbackurl, cd_amount } =
      formData;

    console.log("Submitting form with data:", formData); // Debugging step

    if (!token || !pagename || !pass_key) {
      alert("Please enter all required fields!");
      return;
    }
    if (isreceipt === "false" && !callbackurl) {
      alert("Please enter a callback URL!");
      return;
    }
    if (pagename === "CASH_DEPOSIT") {
      const amount = parseInt(cd_amount);
      if (!amount || amount % 100 !== 0) {
        alert("Please enter amount for cash deposit in multiples of 100!");
        return;
      }
      if (amount > 10000) {
        alert("Maximum amount allowed is ₹10,000!");
        return;
      }
    }

    // Ensure `pagename` is not undefined
    console.log("Final pagename before SDK:", formData.username);
    console.log("Selected pagename:", formData.pagename);

    if (!window.isuSDK) {
      alert("SDK not loaded yet! Please try again.");
      return;
    }

    const isuSDKParams = {
      closeButton: true,
      title: "<img src='logo.png' width='' height='' alt='UAEPS & '>",
      className: "zoom-and-spin",
      clientRefId: "TXN213254215478",
      inputParam: "apisdktestapi",
      pagename: "CASH_DEPOSIT",
      token:
        "in4U8qk/KGlbbZVCC2tcQshkKy4IcBOzz1Z69VSPTvH5vM9YsctxEQTz1QIUSm84TCYktp5z6IRRjumwcMLJ01RVwKhZf5odPJLZB+OoXWp1SkwT51FI+eJTmWXHnvkWMJmR+VWLtTl5hLj2A5fXA/4q0lj+TAGj0wYMRYe+fPL/+BHNRusPu2OP6fXEOweKl0DsPDpOT3Fnlw/4XJJoPzPbgcntMWu6R+e46c0U7roR05I+ixNA7+Vjp3qw4Xjw",
      pass_key: "NNK7BD3GVEEQSFC3AYJW544MFQT2F33DS7OG4NJHWCFOFTG2HCKA",
      cd_amount: 100,
      username: "upitestret",
      isreceipt: "true",
      callbackurl: "",
      paramA: "",
      paramB: "",
      paramC: "",
    };

    console.log(isuSDKParams);
    console.log();

    try {
      window.isuSDK("#uaeps").open(JSON.stringify(isuSDKParams, null, 2));
    } catch (error) {
      console.log(error);
      console.error("ISU SDK Error:", error);
    }
  };

  return (
    <div>
      <div id="uaeps"></div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) =>
          key !== "cd_amount" && key !== "callbackurl" ? (
            <div className="input_container" key={key}>
              <label>{key.replace("_", " ").toUpperCase()}:</label>
              {key === "pagename" || key === "isreceipt" ? (
                <select
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                >
                  {key === "pagename" &&
                    [
                      "CASH_WITHDRAWAL",
                      "MINI_STATEMENT",
                      "BALANCE_ENQUIRY",
                      "ADHAAR_PAY",
                      "CASH_DEPOSIT",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option.replace("_", " ")}
                      </option>
                    ))}
                  {key === "isreceipt" &&
                    ["true", "false"].map((option) => (
                      <option key={option} value={option}>
                        {option === "true" ? "Yes" : "No"}
                      </option>
                    ))}
                </select>
              ) : (
                <input
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                />
              )}
            </div>
          ) : null
        )}

        {showAmountField() && (
          <div className="input_container">
            <label>Amount</label>
            <input
              type="text"
              name="cd_amount"
              value={formData.cd_amount}
              onChange={handleChange}
            />
          </div>
        )}

        {showCallbackField() && (
          <div className="input_container">
            <label>Callback URL</label>
            <input
              type="text"
              name="callbackurl"
              value={formData.callbackurl}
              onChange={handleChange}
            />
          </div>
        )}

        <button type="submit" style={{ marginTop: "20px" }}>
          Submit
        </button>
      </form>
      <p>
        <a href="#" onClick={() => window.location.reload()}>
          Refresh Page
        </a>
      </p>
    </div>
  );
};

export default UAEPS;
