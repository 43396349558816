import common from "../../utill/Common";

class SettlementBanksService {
  fetch = async (token, user_id, search_text, status, page, size) => {
    const url = common.baseUrl + "admin/settlement_banks/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id, search_text, status, page, size }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getImageLink = async (token, user_id, account) => {
    const url = common.baseUrl + "admin/settlement_banks/get_image_link";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id, account }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateBankStatus = async (token, user_id, account, status, remark) => {
    const url = common.baseUrl + "admin/settlement_banks/update_bank_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id, account, status, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}
export default new SettlementBanksService();
