import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import JsonFormatter from "react-json-formatter";

import AuthService from "../../../services/Admin/AuthService";
import SettlementBanksService from "../../../services/Admin/SettlementBanksService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { useHistory } from "react-router-dom";

const ActionDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.selectedData;

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState();

  const remarkRef = useRef();

  const updateHandler = async (user_id, account) => {
    const remark = remarkRef.current.value;

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    props.handleClose();
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementBanksService.updateBankStatus(
        token,
        user_id,
        account,
        status,
        remark
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        //  await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <React.Fragment>
      <Modal
        show={props.open}
        onHide={props.handleClose}
        size="lg"
        style={{ maxWidth: "120%" }}
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && (
              <div className="row">
                <div className="col-md-12">
                  <ul class="list-group list-group-unbordered mb-3">
                    <li class="list-group-item">
                      <b>User ID</b>{" "}
                      <span class="float-right">
                        {data
                          ? data.tbl_user.user_id +
                            " (" +
                            data.tbl_user.name +
                            ")"
                          : ""}
                      </span>
                    </li>
                    <li class="list-group-item">
                      <b>Bank Name</b>{" "}
                      <span
                        class="float-right"
                        //   style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        {data ? data.bank : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Account Number</b>{" "}
                      <span class="float-right">
                        {data ? data.account : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>IFSC Code</b>{" "}
                      <span class="float-right">{data ? data.ifsc : ""}</span>
                    </li>

                    <li class="list-group-item">
                      <b>Account Type</b>{" "}
                      <span class="float-right">
                        {data ? data.account_type : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>POB Type</b>{" "}
                      <span class="float-right">
                        {data ? data.pob_type : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>POB Type</b>{" "}
                      <span class="float-right">
                        <span
                          className="badge badge-primary"
                          onClick={() => {
                            props.getImageLink(data.user_id, data.account);
                          }}
                        >
                          View Uploaded File
                        </span>
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Status</b>{" "}
                      <span class="float-right">{data ? data.status : ""}</span>
                    </li>

                    <li class="list-group-item">
                      <b>Created On</b>{" "}
                      <span class="float-right">
                        {data ? data.created_on : ""}
                      </span>
                    </li>

                    {data.modified_on && (
                      <li class="list-group-item">
                        <b>Last Updated On</b>{" "}
                        <span class="float-right">
                          {data ? data.modified_on : "-"}
                        </span>
                      </li>
                    )}

                    {data.remark && (
                      <li class="list-group-item">
                        <b>Remark</b>{" "}
                        <span class="float-right">
                          {data ? data.remark : "-"}
                        </span>
                      </li>
                    )}
                  </ul>

                  {/* {data && data.status === "Pending" && ( */}
                  <div>
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Status</label>
                      <div class="col-sm-9">
                        <select
                          name="title"
                          class="form-control"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option value="">---Select Status---</option>
                          <option value="Active">Active</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Suspended">Suspended</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Remark</label>
                      <div class="col-sm-9">
                        <textarea
                          ref={remarkRef}
                          className="form-control"
                          placeholder="Remark"
                          rows="3"
                          defaultValue={data ? data.remark : ""}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            {/* {data && data.status === "Pending" && ( */}
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                updateHandler(
                  data ? data.user_id : "",
                  data ? data.account : ""
                );
              }}
            >
              Update
            </button>
            {/* )} */}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ActionDialog;
