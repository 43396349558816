import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/User/AuthService";
import ProfileService from "../../../../services/User/ProfileService";
import SettlementService from "../../../../services/User/SettlementService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const Settlement = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();
  const [settlementBal, setSettlementBal] = useState(0);
  const [transferAmount, setTransferAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("IMPS");
  const [pin, setPin] = useState("");

  useEffect(() => {
    fetchBalance();
    fetchProfile();
  }, []);

  const fetchBalance = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();
      const responseData = await ProfileService.getBalance(token);

      if (responseData.status === "SUCCESS") {
        setSettlementBal(responseData.data.settlement_wallet);
      } else {
        Swal.fire("Oops!", responseData.message, "error");
      }
    } catch (error) {
      Swal.fire("Oops!", HttpError.getError(error), "error");
    }

    loadingCtx.stopLoading();
  };

  const [profileData, setProfileData] = useState();

  const fetchProfile = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();
      const responseData = await ProfileService.fetch(token);

      if (responseData.status === "SUCCESS") {
        setProfileData(responseData.data);
      } else {
        Swal.fire("Oops!", responseData.message, "error");
      }
    } catch (error) {
      Swal.fire("Oops!", HttpError.getError(error), "error");
    }

    loadingCtx.stopLoading();
  };

  const transferHandler = async () => {
    if (!transferAmount || transferAmount <= 0) {
      Swal.fire("Error", "Please enter a valid amount", "error");
      return;
    }

    if (transferAmount < 100 || transferAmount > 200000) {
      Swal.fire("Error", "Amount must be between ₹100 and ₹200,000.", "error");
      return;
    }

    if (!pin || pin.length < 4) {
      Swal.fire(
        "Error",
        "Please enter a valid 4-digit transaction pin",
        "error"
      );
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = AuthService.getUserID();

      const responseData = await SettlementService.transaction(
        token,
        userId,
        pin,
        transferAmount,
        paymentMode
      );

      if (responseData.status === "SUCCESS") {
        Swal.fire(
          "Success",
          `₹${transferAmount} transferred successfully!`,
          "success"
        );
      } else {
        Swal.fire("Oops!", responseData.message, "error");
      }
    } catch (error) {
      Swal.fire("Oops!", HttpError.getError(error), "error");
    }

    // Reset input
    setTransferAmount("");
    setPin("");

    loadingCtx.stopLoading();
    fetchBalance();
  };

  return (
    <Templete t1="Home" t2="Settlement">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-default">
                <div className="card-header">
                  <h3 className="card-title">Settlement</h3>
                  <div class="card-tools">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        history.push("/user/manage_bank_account");
                      }}
                    >
                      Manage Bank Account
                    </button>
                  </div>
                </div>

                <div className="row justify-content-center mt-4 mb-2">
                  <div className="col-md-6">
                    {/* Wallet Balance Card */}
                    <div class="card bg-info text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <h5 class="card-title">
                              Settlement Wallet Balance
                            </h5>
                            <h2 class="font-weight-bold">₹{settlementBal}</h2>
                          </div>
                          <div>
                            <i class="fas fa-wallet fa-3x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-body">
                        {profileData ? (
                          <div>
                            <h5 className="mb-3">Primary Bank Account</h5>

                            <p>
                              <strong>Bank Name:</strong>{" "}
                              {profileData.bank_name}
                            </p>
                            <p>
                              <strong>Account Name:</strong> {profileData.name}
                            </p>
                            <p>
                              <strong>Account Number:</strong>{" "}
                              {profileData.account_number}
                            </p>
                            <p>
                              <strong>IFSC Code:</strong>{" "}
                              {profileData.ifsc_code}
                            </p>
                          </div>
                        ) : (
                          <p className="text-muted">No bank details found.</p>
                        )}
                      </div>
                    </div>
                    {/* Transfer Form */}
                    <div className="card mt-3">
                      <div className="card-body">
                        <h5 className="card-title mb-3">Transfer to Bank</h5>

                        <form autoComplete="off">
                          <div className="form-group">
                            <label
                              // htmlFor="transferAmount"
                              className="font-weight-bold w-100"
                            >
                              Enter Amount
                            </label>

                            <input
                              type="text"
                              id="amount"
                              className="form-control"
                              value={transferAmount}
                              onChange={(e) =>
                                setTransferAmount(e.target.value)
                              }
                              placeholder="Enter amount"
                            />
                            <small className="text-danger">
                              Minimum ₹100 and Maximum ₹200,000 allowed.
                            </small>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="transferAmount"
                              className="font-weight-bold w-100"
                            >
                              Payment Mode
                            </label>

                            <select
                              className="form-control"
                              value={paymentMode}
                              onChange={(e) => setPaymentMode(e.target.value)}
                            >
                              <option value="IMPS" selected>
                                IMPS
                              </option>
                              <option value="NEFT">NEFT</option>
                              <option value="RTGS">RTGS</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="transferAmount"
                              className="font-weight-bold w-100"
                            >
                              Transaction PIN
                            </label>

                            <input
                              type="password"
                              className="form-control"
                              value={pin}
                              onChange={(e) => setPin(e.target.value)}
                              placeholder="Enter PIN"
                              minLength={4}
                              maxLength={4}
                            />
                          </div>

                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={() => {
                              transferHandler();
                            }}
                          >
                            Transfer Now
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Templete>
  );
};

export default Settlement;
