import common from "../../utill/Common";

class SettlementService {
  transaction = async (token, userId, pin, amount, mode) => {
    const url = common.baseUrl + "user/settlement/transaction";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({
        wallet: "SETTLEMENT_WALLET",
        amount: amount,
        mode: mode,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  addBankAccount = async (token, bank, account, ifsc, account_type) => {
    const url = common.baseUrl + "user/settlement/add_bank_account";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        bank,
        account,
        ifsc,
        account_type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteBankAccount = async (token, account) => {
    const url = common.baseUrl + "user/settlement/delete_bank_account";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        account,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  setPrimaryBank = async (token, account) => {
    const url = common.baseUrl + "user/settlement/set_primary_bank";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        account,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  uploadProof = async (token, pob_img, pob_type, account) => {
    const url = common.baseUrl + "user/settlement/upload_proof";

    const dataArray = new FormData();
    dataArray.append("pob_img", pob_img);
    dataArray.append("pob_type", pob_type);
    dataArray.append("account", account);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchBanks = async (token) => {
    const url = common.baseUrl + "user/settlement/fetch_banks";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SettlementService();
