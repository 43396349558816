import common from "../../utill/Common";

class UserService {
  add = async (
    token,
    user_type,
    domain_name,
    package_id,
    pan_number,
    name,
    shop_name,
    dob,
    gender,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address
  ) => {
    const url = common.baseUrl + "admin/users/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_type,
        domain_name,
        package_id,
        pan_number,
        name,
        shop_name,
        dob,
        gender,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAll = async (
    token,
    kyc_status,
    status,
    user_type,
    state,
    id,
    user_id,
    search_text,
    size,
    page
  ) => {
    const url = common.baseUrl + "admin/users/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        kyc_status,
        status,
        user_type,
        state,
        id,
        user_id,
        search_text,
        size,
        page,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchByUserId = async (token, user_id) => {
    const url = common.baseUrl + "admin/users/fetch_by_user_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateKycStatus = async (token, id, kyc_status, remark) => {
    const url = common.baseUrl + "admin/users/update_kyc_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        kyc_status,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateBankDetails = async (
    token,
    user_id,
    bank_name,
    account_number,
    ifsc_code,
    account_type
  ) => {
    const url = common.baseUrl + "admin/users/update_bank_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateUser = async (
    token,
    id,
    name,
    pan_number,
    update_shop_address,
    pincode,
    state,
    city,
    district,
    address
  ) => {
    const url = common.baseUrl + "admin/users/update_user";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        name,
        pan_number,
        update_shop_address,
        pincode,
        state,
        city,
        district,
        address,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateProfile = async (
    token,
    user_id,
    user_type,
    domain_name,
    package_id,
    pan_number,
    name,
    dob,
    gender,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address,
    wallet_hold,
    developer_access,
    isu_bc_agent_id,
    aeps_status,
    status,
    kyc_status
  ) => {
    const url = common.baseUrl + "admin/users/update_profile";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
        user_type,
        domain_name,
        package_id,
        pan_number,
        name,
        dob,
        gender,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        wallet_hold,
        developer_access,
        isu_bc_agent_id,
        aeps_status,
        status,
        kyc_status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  resetLoginDetails = async (token, user_id, password, pin) => {
    const url = common.baseUrl + "admin/users/reset_login_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id, password, pin }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  changeStatus = async (token, id) => {
    const url = common.baseUrl + "admin/users/change_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  xlsUserList = async (token) => {
    const url = common.baseUrl + "admin/users/xls_user_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteUser = async (token, id) => {
    const url = common.baseUrl + "admin/users/delete_user";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchPincode = async (pincode) => {
    const url = `https://api.postalpincode.in/pincode/${pincode}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getBase64Image = async (token, id, name) => {
    const url = common.baseUrl + "admin/users/get_base64_image";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        name,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  faceComparison = async (token, id, type) => {
    const url = common.baseUrl + "admin/users/face_comparison";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  addPSA = async (token, id) => {
    const url = common.baseUrl + "admin/users/add_psa";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getLocation = async (token, pincode) => {
    const url = common.baseUrl + "restapi/db_module/get_location";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ pincode }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
