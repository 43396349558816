import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import ProfileService from "../../../../services/User/ProfileService";
import SettlementService from "../../../../services/User/SettlementService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const AddBankAccount = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const fileTypes = ["PDF"];

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const formRef = useRef();
  const bankRef = useRef();
  const accountRef = useRef();
  const ifscRef = useRef();
  const [accountType, setAccountType] = useState();
  const [pobType, setPobType] = useState();

  const submitHandler = async () => {
    const bank = bankRef.current.value;
    const account = accountRef.current.value;
    const ifsc = ifscRef.current.value;

    if (!bank) {
      Swal.fire("Opps!", "Invalid Bank Name", "error");
      return;
    }

    if (!account) {
      Swal.fire("Opps!", "Invalid Account Number", "error");
      return;
    }

    if (!ifsc) {
      Swal.fire("Opps!", "Invalid IFSC Code", "error");
      return;
    }

    if (!accountType) {
      Swal.fire("Opps!", "Invalid Account Type", "error");
      return;
    }

    if (!pobType) {
      Swal.fire("Opps!", "Invalid Proof of Bank", "error");
      return;
    }

    if (!file) {
      Swal.fire("Opps!", "Please Upload Bank Proof", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementService.addBankAccount(
        token,
        bank,
        account,
        ifsc,
        accountType
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        await uploadHandler(account);

        formRef.current.reset();
        //Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const uploadHandler = async (account) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await SettlementService.uploadProof(
        token,
        file,
        pobType,
        account
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Bank Account">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Bank Account</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/manage_bank_account");
                        }}
                      >
                        Manage Bank Account
                      </button>
                    </div>
                  </div>

                  <div class="row pt-2 pb-2">
                    <div class="col-md-8 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Bank Name
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="BANK NAME"
                                ref={bankRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Account Number
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="ACCOUNT NUMBER"
                                ref={accountRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              IFSC Code
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="IFSC CODE"
                                ref={ifscRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Account Type
                            </label>
                            <div class="col-sm-9">
                              <select
                                class="form-control"
                                value={accountType}
                                onChange={(e) => setAccountType(e.target.value)}
                              >
                                <option>---Select Account Type---</option>
                                <option value="Savings">SAVINGS</option>
                                <option value="Current">CURRENT</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Proof of Bank A/C
                            </label>
                            <div class="col-sm-9">
                              <select
                                class="form-control"
                                value={pobType}
                                onChange={(e) => setPobType(e.target.value)}
                              >
                                <option>---Select Proof of Bank Type---</option>
                                <option value="Bank Passbook">
                                  BANK PASSBOOK
                                </option>
                                <option value="Cancelled Cheque">
                                  CANCELLED CHEQUE
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Upload Proof
                            </label>
                            <div class="col-sm-9">
                              <FileUploader
                                label="Upload Bank Account Proof"
                                multiple={false}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                maxSize="2"
                              />
                              <p>
                                {file
                                  ? `File name: ${file.name}`
                                  : "no files uploaded yet"}
                              </p>
                            </div>
                          </div>

                          <div class="text-center pt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Add Bank Account
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddBankAccount;
